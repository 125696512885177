/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
/* eslint-disable no-alert */
/* eslint-disable no-use-before-define */
import axios from "axios";
import { style } from "style";
import urls from "./urls";
import { LOGOUT_SAML } from "./endpoints";

export const authenticatedSamlLogin = (responseData) => {
  localStorage.setItem("resource-type", "user");
  localStorage.setItem("uid", responseData.nozHeaders.uid);
  localStorage.setItem("idUser", responseData.nozProfile.id);
  localStorage.setItem("client", responseData.nozHeaders.client);
  localStorage.setItem("nameID", responseData.aDProfile.nameID);
  localStorage.setItem("sessionIndex", responseData.aDProfile.sessionIndex);
  localStorage.setItem("access-token", responseData.nozHeaders["access-token"]);
  localStorage.setItem(
    "admin-access",
    responseData.nozProfile.moreThanOneBrand,
  );
  localStorage.setItem("host-header", style.api.hostHeader);

  if (
    responseData.nozProfile.team &&
    responseData.nozProfile.moreThanOneBrand
  ) {
    window.location.assign(urls.ROUTES.CHOOSE_TEAM);
  } else if (responseData.nozProfile.team) {
    styleTeamGrupoPardini(responseData.nozProfile.team);
  } else {
    window.location.assign(urls.ROUTES.MURAL);
  }
};

export const authenticatedSamlLogout = () => {
  const headers = {
    "access-token": localStorage.getItem("access-token"),
    uid: localStorage.getItem("uid"),
    client: localStorage.getItem("client"),
    "session-index": localStorage.getItem("sessionIndex"),
    "name-id": localStorage.getItem("nameID"),
    "host-header": localStorage.getItem("host-header"),
  };

  localStorage.removeItem("uid");
  localStorage.removeItem("idUser");
  localStorage.removeItem("client");
  localStorage.removeItem("nameID");
  localStorage.removeItem("sessionIndex");
  localStorage.removeItem("access-token");
  localStorage.removeItem("resource-type");
  localStorage.removeItem("host-header");

  localStorage.removeItem("menuIcon");
  localStorage.removeItem("nameApp");
  localStorage.removeItem("nameAcademy");
  localStorage.removeItem("nameCalendar");
  localStorage.removeItem("nameTools");
  localStorage.removeItem("primaryColor");
  localStorage.removeItem("secondaryColor");

  const convertHeadersString = new URLSearchParams(headers).toString();

  window.open(`${LOGOUT_SAML}?${convertHeadersString}`, "_parent");
};

export const styleTeamGrupoPardini = (teamPardini) => {
  try {
    axios
      .get(
        `https://base-api.prd.appnoz.com.br/api/v2/clients?team=${teamPardini}`,
      )
      .then((response) => {
        localStorage.setItem("menuIcon", response.data.profile.secondary_logo);
        localStorage.setItem("nameApp", response.data.profile.nameprofile);
        localStorage.setItem("host-header", style.api.hostHeader);
        localStorage.setItem("nameTeam", response.data.profile.team_name);
        localStorage.setItem("nameAcademy", response.data.profile.nameacademy);
        localStorage.setItem(
          "nameCalendar",
          response.data.profile.calendar_name,
        );
        localStorage.setItem("nameTools", response.data.profile.tools_name);

        localStorage.setItem(
          "primaryColor",
          `#${response.data.profile.navigation_background_colors[1]}`,
        );
        localStorage.setItem(
          "secondaryColor",
          `#${response.data.profile.navigation_background_colors[0]}`,
        );
        window.location.assign(urls.ROUTES.MURAL);
      });
  } catch (error) {
    alert("Não foi possivel acessar seu time.");
  }
};
